import React from "react"
import { Link } from "gatsby"
import Button from "../components/Button"

export default ({ title = "Frequently asked questions", answers = [] }) => {

    const answers1 = answers.slice(0, (answers.length) / 2)
    const answers2 = answers.slice((answers.length) / 2)
    return (
        <div className="flex flex-row flex-wrap mb-12">
            <h2 className="text-2xl pt-1 w-full sm:w-1/5 pr-2 mb-2">{title}</h2>
            <div className="flex flex-col w-full sm:w-2/5">
                {answers1.map((answer) => (
                    <div className="sm:p-2">
                        <div className="text-lg mb-2">{answer.q}</div>
                        <div className="text-sm">
                            <p className="mb-4" >
                                {answer.a}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-col w-full sm:w-2/5">
                {answers2.map((answer) => (
                    <div className="sm:p-2">
                        <div className="text-lg mb-2">{answer.q}</div>
                        <div className="text-sm">
                        <p className="mb-4">
                            {answer.a}
                        </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
