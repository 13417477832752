import React from "react"
import { Link } from "gatsby"
import Button from "../components/Button"
import Img from "gatsby-image"
import { motion } from "framer-motion"


export default ({ icon = "", title = "This is a feature", learnTo, img = null, reverse = false, children }) => {
    return (
        <div className={`flex sm:flex-row flex-wrap my-4`}>
            {!reverse && <div className="w-full sm:w-1/2 pr-4 py-4">{img}</div>}
            <div className="flex sm:w-1/2 items-center">
                <div className={`sm:px-6 max-w-md ${learnTo ? 'pt-6' : 'pt-4'}`}>
                    <h3 className="font-bold text-2xl mb-2">{title}</h3>
                    <div className="text-sm">
                        {children}
                    </div>
                    {learnTo && <Link to={learnTo} className="mt-6 flex">
                        <motion.div
                            whileTap={{ scale: 0.95 }}
                            className={`text-indigo-700 hover:text-indigo-500`}>
                                Learn More
                        </motion.div>
                    </Link> }
                </div>
            </div>
            {reverse && <div className="w-full sm:w-1/2 pr-4 py-4">{img}</div>}
        </div>
    )
}
