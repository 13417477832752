import React from "react"
import { Link } from "gatsby"
import Button from "../components/Button"

export default ({ icon = "", title = "This is a feature", children }) => {
    return (
        <div className="w-full sm:w-1/2 md:w-1/3">
            <div className="pr-4 py-4 max-w-md">
                <div className="font-bold text-2xl mb-2">{title}</div>
                <div className="text-m mb-4">
                    {children}
                </div>
            </div>
        </div>
    )
}
