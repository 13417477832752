import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import Toggle from "react-toggle"

import Layout from "../components/layouts/Layout"
import FeatureTile from "../components/FeatureTile"
import Button from "../components/Button"
import FeatureBlock from "../components/FeatureBlock"

import FAQ from "../components/FAQ"
//import Img from "gatsby-image"

var features = [
  {name: "Template creation tools", free: true, starter: true, ent: true},
  {name: "Video and photo gallery", free: true, starter: true, ent: true},
  {name: "Charts and tables", free: true, starter: true, ent: true},
  {name: "Attach files and photos", free: true, starter: true, ent: true},
  {name: "Label translations", free: true, starter: true, ent: true},
  {name: "Batch creation", free: true, starter: true, ent: true},
  {name: "Batch update", free: true, starter: true, ent: true},
  {name: "Dynamic Link Engine", free: true, starter: true, ent: true},
  {name: "Redirection rules (browser type, locale, region)", free: true, starter: true, ent: true},
  {name: "Template and Link localization", free: false, starter: true, ent: true},
  {name: "Geofenced label creation", free: false, starter: true, ent: true},
  {name: "Scan Analytics", free: false, starter: true, ent: true},
  {name: "Augmented Reality viewer", free: false, starter: false, ent: true},
  {name: "Analytics Export", free: false, starter: false, ent: true},
  {name: "API Access", free: false, starter: false, ent: true},
  {name: "CS Verified - Verifiable audit ledger", free: false, starter: false, ent: true},
  {name: "Integration Support", free: false, starter: false, ent: true},
]

const PricingCard = ({ isCenter = false, plan, isAnnual = true }) => (
  <div 
  style={{height: 480}}
  className={
    `max-w-sm rounded overflow-hidden shadow-lg bg-white overflow-visible -m-1 w-full sm:w-1/3 mb-8 mx-auto
    ${isCenter ? 'z-10 -mt-6' : ''}
    `}>
    <div className="px-6 py-6 text-center">
      <div className="font-bold text-xl mb-2">{ plan.name }</div>
      <div className="text-2xl">
        { isAnnual ? plan.price_annual : plan.price }
      </div>
      <div className="mb-8">
        {isCenter ? `per month` : ''}
        {isAnnual && isCenter && <div className="text-sm text-gray-700">$420 Billed yearly</div>}
      </div>
      
      {plan.name === 'Enterprise' ? <a href="mailto:will@counterset.com" className={`items-center btn ${isCenter ? 'btn-black' : ''}`}>{ plan.buttonText }</a> : <a href="https://dashboard.counterset.com/#/signup" className={`items-center btn ${isCenter ? 'btn-black' : ''}`}>{ plan.buttonText }</a>}
      <div className="text-center pt-6">
        <div className="font-bold text-m mb-2">Features</div>
        { plan.features.map((feature) =>
          <p className="text-gray-700 text-sm">{feature}</p>
        )}

      </div>
    </div>
  </div>
)

const header = (
  <div style={{height: 320}} className={`bg-gray-900`}>
    <div className="w-full max-w-screen-xl mx-auto px-6">
      <div className="pt-16 text-center">
        <h1 className="text-4xl text-white">Extend your packaging</h1>
        <p className="text-lg text-gray-300 font-normal">No credit card required to start</p>
      </div>
    </div>
  </div>
)

export default ({data}) => {
  const [isAnnual, setIsAnnual] = useState(true);

  return <div>
  <Layout header={header}>
    <Helmet>
      <title>Pricing</title>
      <meta name="description" content="Extend your packaging with CounterSet" />
      <meta property="og:description" content="Extend your packaging with CounterSet" />
      <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What is a Unique Code?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A Unique Code is a single unique scannable asset code within the CounterSet platform. An asset code can be associated with SKU, Batch or individual product unit level information."
              }
            }, {
              "@type": "Question",
              "name": "Can I be billed annually?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, annual billing can be enabled within the CounterSet Dashboard."
              }
            }, {
              "@type": "Question",
              "name": "What forms of payment do you accept?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We accept all major credit and debit cards: Visa, Mastercard, American Express, JCB, Discover and Diners Club. Payments are powered by Stripe."
              }
            }, {
              "@type": "Question",
              "name": "Where is CounterSet hosted?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "CounterSet utilizes Amazon Web Services located in Canada. We use industry-standard AES-256 encryption for data at rest."
              }
            }]
          }`}</script>
    </Helmet>
      <div className="-mt-32">
        <div className="flex items-center w-full pb-12 justify-center">
        <label className="text-center text-white">
          <span className="pr-4">Bill Monthly </span>
          <Toggle
            defaultChecked={isAnnual}
            icons={false}
            onChange={(e) => setIsAnnual(e.target.checked)} />
          <span className="pl-4">Bill Annually</span>
        </label>
        </div>
        <div className="flex flex-row flex-wrap mb-12">
          <PricingCard plan={data.allPricingJson.nodes[0]}></PricingCard>
          <PricingCard isAnnual={isAnnual} isCenter={true} plan={data.allPricingJson.nodes[1]}></PricingCard>
          <PricingCard plan={data.allPricingJson.nodes[2]}></PricingCard>
        </div>
      </div>

    <div className="pt-4 pb-6">
      <h2 className="text-3xl text-center">Plan Features</h2>
    </div>
    
    <table className="text-left text-xs w-full mb-6">
		<thead className="bg-white flex text-black w-full">
			<tr className="flex w-full mb-4">
				<th className="p-2 w-2/6 md:w-2/4">Feature</th>
				<th className="p-2 w-1/6 text-center">Community</th>
				<th className="p-2 pl-10 w-1/6 text-center">Team</th>
				<th className="p-2 pl-12 w-1/6 text-center">Enterprise</th>
			</tr>
		</thead>
    <tbody className="bg-grey-light flex flex-col items-center justify-between w-full">
      {features.map((feature) => <tr className="flex w-full mb-4">
				<td className="p-2 w-2/6 md:w-2/4">{feature.name}</td>
        <td className="p-2 w-1/6 text-center">{feature.free && <Img fixed={data.check.fixed}></Img>}</td>
				<td className="p-2 pl-10 w-1/6 text-center">{feature.starter && <Img fixed={data.check.fixed}></Img>}</td>
				<td className="p-2 pl-12 w-1/6 text-center">{feature.ent && <Img fixed={data.check.fixed}></Img>}</td>
			</tr>)}
		</tbody>
	</table>

    <FAQ answers={[
      {
        q: 'What is a Unique Code?',
        a: 'A Unique Code is a single unique scannable asset code within the CounterSet platform. An asset code can be associated with SKU, Batch or individual product unit level information.'
      }, {
        q: 'Can I be billed annually?',
        a: 'Yes, annual billing can be enabled within the CounterSet Dashboard.'
      }, {
        q: 'What forms of payment do you accept?',
        a: 'We accept all major credit and debit cards: Visa, Mastercard, American Express, JCB, Discover and Diners Club. Payments are powered by Stripe.'
      },
      {
        q: 'Where is CounterSet hosted?',
        a: 'CounterSet utilizes Amazon Web Services located in Canada. We use industry-standard AES-256 encryption for data at rest.'
      }
    ]}></FAQ>
  </Layout>
  </div>
}

export const query = graphql`

  query Pricing {
    allPricingJson {
      nodes {
        name
        price
        price_annual
        buttonText
        features
      }
    }
    check: imageSharp(fixed: {originalName: {eq: "check.png"} }) {
      fixed(height: 20) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
